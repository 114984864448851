import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core'
import { NbAuthService, NbTokenService } from '@nebular/auth'
import { Subject, Observable } from 'rxjs'
import { map, filter, takeUntil } from 'rxjs/operators'
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme'
import { LayoutService } from '../../../@core/utils'
import { RippleService } from '../../../@core/utils/ripple.service'
import { RouterPath } from '../../../config/global-const'
import { ActivatedRoute, Router } from '@angular/router'
import { LocalStorageService } from '../../../@core/utils'
import { UserService } from '../../../@core/services/user.service'
import { SharedService } from '../../../@core/services/shared.service'
import { DOCUMENT } from '@angular/common'
import { ConfigService } from '../../../@core/services/config.service'

@Component({
    selector: 'ngx-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    private destroy$: Subject<void> = new Subject<void>()
    public readonly materialTheme$: Observable<boolean>
    userPictureOnly: boolean = false

    themes = [
        {
            value: 'default',
            name: 'Light',
        },
        {
            value: 'dark',
            name: 'Dark',
        },
    ]

    currentTheme = 'default'

    userMenu = [{ title: 'My account' }, { title: 'Sign out' }]
    constructor(
        private authService: NbAuthService,
        private nbTokenService: NbTokenService,
        private router: Router,
        private route: ActivatedRoute,
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private layoutService: LayoutService,
        private breakpointService: NbMediaBreakpointsService,
        private localStorageService: LocalStorageService,
        private rippleService: RippleService,
        private usersService: UserService,
        public sharedService: SharedService,
        private configService: ConfigService,
        @Inject(DOCUMENT) private _document: Document
    ) {
        this.materialTheme$ = this.themeService.onThemeChange().pipe(
            map((theme) => {
                const themeName: string = theme?.name || ''
                return themeName.startsWith('material')
            })
        )
    }

    ngOnInit(): void {
        this.configService.getAndSaveLatestConfig()
        this.currentTheme = this.themeService.currentTheme
        const { xl } = this.breakpointService.getBreakpointsMap()
        this.themeService
            .onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$)
            )
            .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl))
        this.themeService
            .onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$)
            )
            .subscribe((themeName) => {
                this.currentTheme = themeName
                this.rippleService.toggle(themeName?.startsWith('material'))
            })
        // handle menu item click
        this.menuService.onItemClick().subscribe((menu) => {
            // toggle sidebar after menu item clicked
            if (window.innerWidth < 1200 && window.innerWidth >= 576) {
                this.sidebarService.compact('menu-sidebar')
            } else if (window.innerWidth < 576) {
                this.sidebarService.collapse('menu-sidebar')
            }
            // My account
            if (menu.item.title === 'My account') {
                void this.router.navigate([RouterPath.PROFILE.FULL_PATH])
            }
            // log out
            if (menu.item.title === 'Sign out') {
                this.authService.logout('email').subscribe(() => {
                    this.nbTokenService.clear()
                    this.localStorageService.clearAll()
                    this.sharedService.clear()
                    void this.router.navigate([RouterPath.AUTH.LOGIN.FULL_PATH])
                })
            }
        })
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }

    ngAfterViewInit(): void {
        if (window.innerWidth >= 576) {
            this.sidebarService.compact('menu-sidebar')
            this.layoutService.changeLayoutSize()
        }
    }

    changeTheme(themeName: string) {
        this.themeService.changeTheme(themeName)
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar')
        this.layoutService.changeLayoutSize()

        return false
    }

    navigateHome() {
        this.menuService.navigateHome()
        return false
    }

    onSalonChanged(selectedId): void {
        this.sharedService.currentSalon = this.sharedService.salons.find((s) => s.id == selectedId)
        // reload page
        window.location.href = window.location.origin // go to homepage
    }
}
