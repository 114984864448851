import { Injectable } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { ca } from 'date-fns/locale'
import { DefaultLanguage } from '../../config/global-const'

const LocalStorageConst = {
    ACCESS_TOKEN: 'accessToken',
    REFRESH_TOKEN: 'refreshToken',
    CURRENT_USER: 'currentUser',
    CURRENT_SALON: 'currentSalon',
    SALONS: 'salons',
    UPDATED_AT: 'updatedAt',
    ASSETS_PATH: 'assetPath',
    LANGUAGE: 'language',
}

@Injectable()
export class LocalStorageService {
    AES_PASSWORD: string = 'g9S7&oAuIKV4'
    get currentUser(): any {
        const userString = localStorage.getItem(LocalStorageConst.CURRENT_USER)
        if (userString && userString.length > 0) {
            try {
                return JSON.parse(userString)
            } catch (error) {
                return null
            }
        } else {
            return null
        }
    }

    set currentUser(userToSet: any) {
        userToSet = JSON.stringify(userToSet)
        localStorage.setItem(LocalStorageConst.CURRENT_USER, userToSet)
    }

    get currentSalon(): any {
        const salonString = localStorage.getItem(LocalStorageConst.CURRENT_SALON)
        try {
            return typeof salonString === 'string' ? JSON.parse(salonString) : salonString
        } catch (e) {
            console.log(e)
            return null
        }
        return null
    }

    set currentSalon(salon: any) {
        localStorage.setItem(LocalStorageConst.CURRENT_SALON, JSON.stringify(salon))
    }

    get salons(): any {
        const schoolString = localStorage.getItem(LocalStorageConst.SALONS)
        try {
            return typeof schoolString === 'string' ? JSON.parse(schoolString) : schoolString
        } catch (e) {
            // console.log(e)
        }
        return []
    }

    set salons(salons: any) {
        localStorage.setItem(LocalStorageConst.SALONS, JSON.stringify(salons))
    }

    get accessToken(): string {
        try {
            const encryptedMessage = localStorage.getItem(LocalStorageConst.ACCESS_TOKEN)
            const bytes = CryptoJS.AES.decrypt(encryptedMessage, this.AES_PASSWORD)
            const decrypted = bytes.toString(CryptoJS.enc.Utf8)
            return decrypted || 'access-token-null'
        } catch (e) {
            return 'stored-access-token-error'
        }
    }

    set accessToken(value: string) {
        const encryptedMessage = CryptoJS.AES.encrypt(value, this.AES_PASSWORD).toString()
        localStorage.setItem(LocalStorageConst.ACCESS_TOKEN, encryptedMessage)
    }

    get refreshToken(): string {
        try {
            const encryptedMessage = localStorage.getItem(LocalStorageConst.REFRESH_TOKEN)
            const bytes = CryptoJS.AES.decrypt(encryptedMessage, this.AES_PASSWORD)
            const decrypted = bytes.toString(CryptoJS.enc.Utf8)
            return decrypted || 'refresh-token-null'
        } catch (e) {
            return 'stored-refresh-token-error'
        }
    }

    set refreshToken(value: string) {
        const encryptedMessage = CryptoJS.AES.encrypt(value, this.AES_PASSWORD).toString()
        localStorage.setItem(LocalStorageConst.REFRESH_TOKEN, encryptedMessage)
    }

    get assetsPath(): string {
        return localStorage.getItem(LocalStorageConst.ASSETS_PATH)
    }

    set assetsPath(path: string) {
        localStorage.setItem(LocalStorageConst.ASSETS_PATH, path)
    }

    get currentLanguageCode(): string {
        return localStorage.getItem(LocalStorageConst.LANGUAGE) || DefaultLanguage
    }

    set currentLanguageCode(newLanguageCode) {
        localStorage.setItem(LocalStorageConst.LANGUAGE, newLanguageCode)
    }

    get updatedAt() {
        return localStorage.getItem(LocalStorageConst.UPDATED_AT)
    }

    set updatedAt(epoch) {
        localStorage.setItem(LocalStorageConst.UPDATED_AT, epoch)
    }

    public clearAll() {
        localStorage.removeItem(LocalStorageConst.ACCESS_TOKEN)
        localStorage.removeItem(LocalStorageConst.REFRESH_TOKEN)
        localStorage.removeItem(LocalStorageConst.CURRENT_USER)
        localStorage.removeItem(LocalStorageConst.ASSETS_PATH)
        localStorage.removeItem(LocalStorageConst.LANGUAGE)
        localStorage.removeItem(LocalStorageConst.UPDATED_AT)
        localStorage.removeItem(LocalStorageConst.SALONS)
        localStorage.removeItem(LocalStorageConst.CURRENT_SALON)
        localStorage.clear()
    }
}
