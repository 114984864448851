<div fxFill class="page-container" fxLayoutAlign="center center" fxLayout="column">
  <div fxLayout="row">
    <img width="450" class="welcome-image" src="/assets/images/ud/ud_relax_red.svg" />
  </div>
  <div fxLayout="row">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="error">404</div>
      <p>Page not found.</p>
    </div>
  </div>
</div>
