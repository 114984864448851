import { NgModule } from '@angular/core'
import { ExtraOptions, Routes, RouterModule } from '@angular/router'
import { Error404Component } from './@core/errors/error404/error404.component'
import { Error500Component } from './@core/errors/error500/error500.component'
import { AuthGuard } from './@core/guards/auth-guard.service'
import { RouterPath } from './config/global-const'

export const routes: Routes = [
    // for local env
    {
        path: 'example/oauth2/callback',
        redirectTo: RouterPath.AUTH.GOOGLE_CALLBACK.FULL_PATH,
    },
    {
        path: RouterPath.AUTH.PATH,
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    },
    // home route protected by auth guard
    { path: RouterPath.ERRORS._500, component: Error500Component },
    { path: RouterPath.ERRORS._404, component: Error404Component },
    { path: '**', redirectTo: RouterPath.WORKING.FULL_PATH },

    // otherwise redirect to home
    // { path: '**', redirectTo: '' }
]

const config: ExtraOptions = {
    useHash: false,
}

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
