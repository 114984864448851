import { Injectable, Injector } from '@angular/core'
import { PvsRequestFactoryService } from './pvs-request-factory.service'
import { ApiPath } from '../../config/global-const'
import { SharedService } from './shared.service'

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _sharedService: SharedService
    constructor(private requestService: PvsRequestFactoryService, private injector: Injector) {}
    private get sharedService(): SharedService {
        if (!this._sharedService) {
            this._sharedService = this.injector.get(SharedService)
        }
        return this._sharedService
    }
    public getProfile() {
        const url = this.requestService.createUrl(ApiPath.USER.PROFILE)
        return this.requestService.requestGet(url)
    }
    async getCurrentSalon(): Promise<any> {
        try {
            if (this.sharedService.currentSalon) {
                return {
                    currentSalon: this.sharedService.currentSalon,
                }
            }
            // get
            const request = this.getProfile()
            request.usingLoadingBar = false
            const response = await request.toPromise()

            this.sharedService.salons = response?.data?.salons || []

            let currentSalon = this.sharedService.currentSalon
            if (currentSalon) {
                // check saved salon in storage is valid or not
                currentSalon = this.sharedService.salons.find((s) => s.id === currentSalon.id)
            }
            if (!currentSalon) {
                // set default salon
                if (this.sharedService.salons.length) {
                    currentSalon = this.sharedService.salons[0]
                }
                // currentSalon = this.sharedService.salons.find((s) => s?.me?.isDefault === true)
            }
            this.sharedService.currentSalon = currentSalon
            return {
                currentSalon: this.sharedService.currentSalon,
            }
        } catch (e) {
            return {
                currentSalon: null,
            }
        }
    }

    updateProfile(data, avatarFile?: File) {
        const url = this.requestService.createUrl(ApiPath.USER.PROFILE_FORM_STRINGIFY.PATH)
        const formData = this.requestService.buildFormStringify(data, avatarFile, 'avatar')
        return this.requestService.requestPut(url, formData, true)
    }
    changePassword(password, newPassword) {
        const url = this.requestService.createUrl(ApiPath.USER.CHANGE_PASSWORD)
        return this.requestService.requestPut(url, { password: password, newPassword: newPassword })
    }
}
